import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, retry, throwError } from 'rxjs';
import { Eventi } from '../interfaces/eventi';
import { AuthResponse } from '../interfaces/auth-response';
import { EventiDtoNumber } from '../interfaces/eventi-dto-number';
import {
  EventFormLogin,
  EventFormLoginUsers,
} from '../interfaces/event-form-login';

@Injectable({
  providedIn: 'root',
})
export class EventiService {
  apiUrl: string = environment.apiUrl;
  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

  constructor(private http: HttpClient) {}

  getAllEventi = (): Observable<Eventi[]> =>
    this.http.get<Eventi[]>(`${this.apiUrl}Eventi/getAll`).pipe(retry({count:3, delay:2000}));

  getNumber = (): Observable<EventiDtoNumber> =>
    this.http.get<EventiDtoNumber>(`${this.apiUrl}Eventi/getNumber`).pipe(retry({count:3, delay:2000}));

  getEvento = (ids: string): Observable<Eventi> =>
    this.http.get<Eventi>(`${this.apiUrl}Eventi/get?id=${ids}`).pipe(retry({count:3, delay:2000}));

  getUserLoggedInEvent = (eventid: string) =>
    this.http.get<EventFormLoginUsers>(
      `${this.apiUrl}Eventi/getloginuserevent?id=${eventid}`
    ).pipe(retry({count:3, delay:2000}));

  createEvent(event: Eventi): Observable<{ message: string }> {
    return this.http.post<AuthResponse>(`${this.apiUrl}Eventi/create`, event).pipe(retry({count:3, delay:2000}));
  }

  sendEventEntering(event: EventFormLogin): Observable<{ message: string }> {
    return this.http.post<AuthResponse>(
      `${this.apiUrl}Eventi/enteringevent`,
      event
    ).pipe(retry({count:3, delay:2000}));
  }

  isInEvent = (usr: string, event: string): Observable<AuthResponse> => 
    this.http.get<AuthResponse>(`${this.apiUrl}Eventi/isInEvent?usr=${usr}&eId=${event}`).pipe(retry({count:3, delay:2000}));

  deleteEvent = (id: string): Observable<{ message: string }> =>
    this.http.delete<{ message: string }>(
      `${this.apiUrl}Eventi/delete?id=${id}`
    ).pipe(retry({count:3, delay:2000}));

  modifyEvent(event: FormData): Observable<{ message: string }> {
    return this.http.patch<any>(`${this.apiUrl}Eventi/modify`, event).pipe(retry({count:3, delay:2000}));
  }

  userforeventadd(data: any): Observable<{ message: string }> {
    return this.http.post<any>(`${this.apiUrl}Eventi/userforeventadd`, data).pipe(retry({count:3, delay:2000}));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Errore: ', error.error);
    } else {
      console.error(
        `Errore con codice ${error.status}: `,
        error.error.message as AuthResponse
      );
    }

    return throwError(
      () => new Error('Qualcosa è andato storto; Riprovare più tardi')
    );
  }
}
